<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				category: {},
				artCat: {},
			}
		},
		computed: {
			mySlug() {
				return this.$route.params.slug
			}
		},
		async mounted() {
			await this.ready();
			this.getArticle()
			setTimeout(()=>{ $("a[href$='informasi-kesehatan']").closest("li").addClass("active") }, 300)
		},
		methods: {
			getArticle() {
				Gen.apirest("/health-article-terkait/" + this.mySlug, {}, (err, resp) => {
					if (err) console.log(err)
					if (resp.code == 404) return this.$router.push({name:'404'})
					this.category = resp.category
					this.artCat = resp.artCat
					setTimeout(()=>{
						global.SEMICOLON.initialize.fullScreen()
						Gen.loadScript("/frontend/js/custom.js", false)
						$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
					}, 100)
				})
			},
			removeTop(){
				document.querySelector("#slider.half-slider .slider-caption").removeAttribute("style");
			}
		},
		watch:{
			$route(){
				this.getArticle()
				this.removeTop()
			}
		}
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element force-full-screen half-slider full-screen clearfix" data-loop="true"
			data-autoplay="5000">
			<div class="force-full-screen full-screen" :style="'background-image: url('+uploader(category.mht_banner_image)+');'"
				:data-hero-mobile="uploader(category.mht_banner_image)" data-aos="zoom-out">

				<div class="container clearfix">
					<div class="slider-caption slider-caption-center">
						<h2 data-animate="fadeInDown">{{category.mht_banner_title}}</h2>
						<p data-animate="fadeInUp" data-delay="400">{{category.mht_banner_desc}}</p>
					</div>
				</div>

			</div>
		</section>

		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin health-exp">
					<div class="container">
						<div class="exp-line">
							<div class="row justify-content-center">
								<div v-for="(c,k) in artCat" :key="k" :class="k>2?'col-md-6':'col-md-6 col-lg-4'" data-aos="fade-up" :data-aos-delay="(k*100)">
									<router-link :to="{name:'HealthInfoDetailArticle', params:{slug:mySlug,sub:c.mhc_slug}}" class="exp-item">
										<div class="exp-bg" :style="'background-image:url('+uploader(c.mhc_image)+')'" data-aos="zoom-out" :data-aos-delay="((k+1)*100)"></div>
										<div class="exp-title" :class="k>2 ? 'd-lg-inline-block':''">
											<img :src="uploader(c.mhc_logo)" :alt="c.mhc_name">
										</div>
										<div class="exp-desc" :class="k>2 ? 'd-lg-inline-block':''">
											<h3>{{c.mhc_name}}</h3>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	</div>
</template>